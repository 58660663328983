import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="row backNavGreen">
      <div className="col-md-12" id="navCrumb">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row" id="pagenotfound">
                <div className="col-12">
                  {" "}
                  <h4 className="capitalise">404</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="alignMiddle404">
        <h2 className="text-center">404</h2>
        <h3 className="text-center capitalise">Page Not found</h3>
        {/* <p className="text-center">
          You just hit a route that doesn&#39;t exist... the sadness.
        </p> */}
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
